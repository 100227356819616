import clsx from "clsx";

const HorizontalRule = ({ colorClass = "gray-200", width = "screen" }) => {
  return (
    <div
      className={clsx("HorizontalRule", {
        container: width === "layout",
      })}
    >
      <hr
        className={clsx("HorizontalRule__hr", `border-t border-${colorClass}`)}
      />
    </div>
  );
};

export default HorizontalRule;
