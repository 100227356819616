import clsx from "clsx";
import { ArrowRight } from "react-feather";

const ButtonLabel = ({
  label = "Label",
  color = "currentColor",
  size = "md",
}) => {
  return (
    <div
      className={clsx(
        "ButtonLabel",
        "flex items-center whitespace-nowrap text-current"
      )}
    >
      <span
        className={clsx(
          "ButtonLabel__label",
          "small-caps font-sans leading-none",
          {
            "text-base": size === "lg",
            "text-sm": size === "md",
            "text-xs": size === "sm",
          }
        )}
      >
        {label}
      </span>
    </div>
  );
};

export default ButtonLabel;
