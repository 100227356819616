import Image from "@/components/WrappedImage";
import { getImageColor, isColorDark } from "@/lib/colorUtilities";
import preventWidows from "@/lib/preventWidows";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ImageCarousel = ({ slides }) => {
  const duplicatedSlides = slides
    .concat(slides)
    .map((slide, i) => ({ ...slide, key: i }));

  return (
    <div className={clsx("ImageCarousel")}>
      <div className={clsx("max-sm:px-6")}>
        <Swiper
          spaceBetween={24}
          slidesPerView={1}
          className={clsx("")}
          centeredSlides
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          navigation
          loop
          breakpoints={{
            "640": {
              slidesPerView: "auto",
            },
          }}
        >
          {duplicatedSlides.map((slide) => {
            const caption = slide.caption;
            const image = slide.image?.[0];

            let aspect = image.width > image.height ? "landscape" : "portrait";

            if (image.width === image.height) aspect = "square";

            return (
              <SwiperSlide
                key={slide.key}
                className={clsx("relative max-[320px]:px-6", {
                  "!w-full lg:!w-[768px] xl:!w-[1024px]":
                    aspect === "landscape",
                  "!w-[56.25%] lg:!w-[432px] xl:!w-[576px]":
                    aspect === "portrait",
                  "!w-3/4 lg:!w-[576px] xl:!w-[768px]": aspect === "square",
                })}
              >
                <div className={clsx("flex h-full w-full items-center")}>
                  <_SlideImage
                    image={image}
                    aspect={aspect}
                    caption={caption}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

const getSizes = (aspect) => {
  switch (aspect) {
    case "landscape":
      return `(max-width: 640px) 100vw, (min-width: 641px) 100vw, (min-width: 1024px) 768px, (min-width: 1280px) 1024px`;
    case "portrait":
      return `(max-width: 640px) 100vw, (min-width: 641px) 56.25vw, (min-width: 1024px) 432px, (min-width: 1280px) 576px`;
    case "square":
    default:
      return `(max-width: 640px) 100vw, (min-width: 641px) 75vw, (min-width: 1024px) 576px, (min-width: 1280px) 768px`;
  }
};

const _SlideImage = ({ image, aspect, caption }) => {
  const [isDark, setIsDark] = useState(true);

  useEffect(() => {
    // Get the colour of the image
    let isDark = true;
    let color = getImageColor(image.url).then((color) => {
      if (!!color?.hex) setIsDark(isColorDark(color.hex));
    });
  }, [image.url]);

  return (
    <div className={clsx("relative w-full")}>
      <div
        className={clsx("relative", {
          "aspect-4/3": aspect === "landscape",
          "aspect-3/4": aspect === "portrait",
          "aspect-square": aspect === "square",
        })}
      >
        <Image
          src={image.url}
          alt={image.title}
          fill
          className={"object-cover"}
          sizes={getSizes(aspect)}
        />
      </div>
      {!!caption && (
        <div
          className={clsx(
            "absolute bottom-0 left-0 right-0 px-6 py-4 text-sm",
            {
              "text-white": isDark,
              "text-black": !isDark,
            },
          )}
          dangerouslySetInnerHTML={{ __html: preventWidows(caption) }}
        ></div>
      )}
    </div>
  );
};

export default ImageCarousel;
