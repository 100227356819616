import ButtonLabel from "@/components/ButtonLabel";
import Target from "@/components/Target";
import clsx from "clsx";
import Link from "next/link";

const Button = ({
  target,
  href,
  label,
  full = false,
  size = "md",
  theme = "red",
}: {
  target?: any;
  href?: string;
  label?: string;
  full?: boolean;
  size?: "lg" | "md" | "sm";
  theme?: "red" | "white";
}) => {
  const Wrapper = ({ children, target, className }) => {
    if (!!href) {
      return (
        <Link href={href} className={className}>
          {children}
        </Link>
      );
    }

    return (
      <Target target={target} className={className}>
        {children}
      </Target>
    );
  };

  return (
    <Wrapper
      target={target}
      className={clsx(
        "group relative rounded-[100%] duration-300 ease-out text-center transition active:top-[1px] inline-flex justify-center items-center",
        {
          "bg-red text-stone hover:opacity-90": theme === "red",
          "bg-stone text-red hover:opacity-90": theme === "white",
          "w-full ": full,
          "py-5 px-8 lg:py-6 lg:px-12": size === "lg",
          "py-4 px-6 lg:py-5 lg:px-8": size === "md",
          "py-4 px-5 lg:py-4 lg:px-4": size === "sm",
        },
      )}
    >
      <ButtonLabel label={!!target?.text ? target.text : label} size={size} />
    </Wrapper>
  );
};

export default Button;
