import reactPlayerConfig from "@/lib/reactPlayerConfig";
import clsx from "clsx";
import dynamic from "next/dynamic";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

const Video = ({
  videoUrl,
  autoPlay = false,
  controls = true,
  loop = false,
}: {
  videoUrl: string;
  autoPlay: boolean;
  controls: boolean;
  loop: boolean;
}) => {
  return (
    <div className={clsx("Video")}>
      <div className={clsx("relative aspect-16/9")}>
        <ReactPlayer
          url={videoUrl}
          controls={controls}
          width="100%"
          height="100%"
          className={clsx("absolute inset-0")}
          playing={autoPlay}
          muted={autoPlay}
          playsinline
          config={reactPlayerConfig}
          loop={loop}
        />
      </div>
    </div>
  );
};

export default Video;
