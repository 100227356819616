import { screens } from "@/tailwind-config.client";

const getSizes = (breakpoints: Record<string, string>) => {
  let sizes = `100vw`; // default value for the smallest screens
  for (let breakpoint in breakpoints) {
    const width = screens[breakpoint];
    if (width) {
      sizes = `(min-width: ${width}) ${breakpoints[breakpoint]}, ${sizes}`;
    }
  }
  return sizes;
};

export default getSizes;
