import Image from "@/components/WrappedImage";
import getSizes from "@/lib/getSizes";
import clsx from "clsx";
import padStart from "lodash/padStart";
import Link from "next/link";

const EntryList = ({ entries = [] }: { entries: any[] }) => {
  return (
    <div className={clsx("EntryList", "container")}>
      {entries.map((entry, _index) => {
        const image = entry.image?.[0];

        const number = padStart(_index + 1, 2, "0") + ".";

        return (
          <div
            key={entry.id}
            className={clsx(
              "EntryList",
              "border-t border-stone/25 py-8 lg:py-12",
            )}
          >
            <div className={clsx("grid grid-cols-24 ")}>
              <div className={clsx("hidden lg:col-span-1 lg:block")}>
                {number}
              </div>
              <div className={clsx("col-span-24 lg:col-span-4")}>
                <Link
                  href={`/${entry.uri}`}
                  className={clsx(
                    "relative mb-8 block aspect-3/2 bg-white/20 lg:mb-0",
                  )}
                >
                  {!!image && (
                    <Image
                      src={image.url}
                      alt={image.title}
                      fill
                      className={"object-cover"}
                      sizes={getSizes({ xl: "10vw", "2xl": "221px" })}
                    />
                  )}
                </Link>
              </div>
              <div className={clsx("col-span-24 lg:col-span-11")}>
                <h3 className={clsx("relative mb-4 flex lg:pl-16")}>
                  <Link href={`/${entry.uri}`}>
                    <span
                      className={clsx(
                        "relative -top-[1px] inline pr-4 text-xs lg:hidden",
                      )}
                    >
                      {number}
                    </span>
                    <span className={clsx("heading-md")}>{entry.title}</span>
                  </Link>
                </h3>
              </div>
              <div className={clsx("col-span-24 lg:col-span-7")}>
                <div>{entry.summary}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EntryList;
