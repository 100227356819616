import clsx from "clsx";
import Image from "next/image";
import { useState } from "react";

const WrappedImage = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  // Handle classnames
  const className = clsx(props.className, "fade-in", {
    "opacity-0": !props.priority && !isLoaded,
    "opacity-100": !props.priority && isLoaded,
  });

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image {...props} onLoad={handleLoad} className={className} />
  );
};

export default WrappedImage;
