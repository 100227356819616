import Chroma from "chroma-js";
import { FastAverageColor } from "fast-average-color";

export const getImageColor = async (src) => {
  const fac = new FastAverageColor();

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(fac.getColor(img));
    img.onerror = reject;
    img.src = src;
    img.crossOrigin = "Anonymous";
  });
};

export const isHexColor = (hex) => {
  return (
    typeof hex === "string" && hex.length === 6 && !isNaN(Number("0x" + hex))
  );
};

export const isColorDark = (hex) => {
  if (!hex) return false;

  const color = Chroma(hex);
  return color.get("lab.l") < 70;
};
