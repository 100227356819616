const reactPlayerConfig = {
  youtube: {
    playerVars: {
      showinfo: 0,
      modestbranding: 1,
      rel: 0,
    },
  },
  vimeo: {
    playerOptions: {
      color: "ffffff",
      byline: false,
      portrait: false,
      responsive: true,
      transparent: true,
    },
  },
};

export default reactPlayerConfig;
