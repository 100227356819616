import clsx from "clsx";

const stripSpans = (html: string) => {
  return html.replace(/<span>(.*?)<\/span>/g, "$1");
};

const BodyText = ({ text, textAlignment = "left" }) => {
  return (
    <div
      className={clsx(
        "prose-h2:font-heading prose-h3:font-heading prose-i:font-italic prose text-gray-900 dark:prose-a:text-gray-100 prose-h2:font-medium prose-h3:font-medium prose-a:font-sans prose-a:decoration-from-font prose-a:underline-offset-4 hover:prose-a:decoration-gray-300 hover:prose-a:opacity-75 dark:prose-strong:text-gray-100 dark:prose-em:text-gray-100 hover:prose-a:transition-all prose-strong:font-semibold prose-li:my-0 dark:text-gray-100 md:prose-lg lg:prose-xl",
        {
          "text-left": textAlignment === "left" || !textAlignment,
          "text-center": textAlignment === "center",
          "text-right": textAlignment === "right",
        },
      )}
      dangerouslySetInnerHTML={{ __html: stripSpans(text) }}
    ></div>
  );
};

export default BodyText;
